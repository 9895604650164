import React, { useState } from "react";

function OpenAiRequest() {
  // State variables for the input fields
  const [apiKey, setApiKey] = useState("");
  const [article, setArticle] = useState("");
  const [tprompt, setTprompt] = useState("");
  const [tokens, setTokens] = useState(100);
  const [response, setResponse] = useState("");

  // Function to handle form submission and make the HTTP request
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      model: "gpt-4o-mini-2024-07-18",
      messages: [
        { role: "system", content: "You are a helpful assistant." },
        { role: "user", content: `${tprompt}: ${article}` },
      ],
      max_tokens: parseInt(tokens),
    };

    try {
      const res = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await res.json();

      // Check if the response is valid and contains choices
      if (data.choices && data.choices.length > 0) {
        setResponse(data.choices.map((choice, index) => `${index + 1}: ${choice.message.content}`).join("\n"));
      } else {
        setResponse("No response choices found.");
      }
    } catch (error) {
      console.error("Error:", error);
      setResponse("An error occurred while fetching the response.");
    }
  };

  return (
    <div>
      {/* Section 1: Input Form */}
      <section style={{ padding: "20px", borderBottom: "1px solid #ddd" }}>
        <h2>Customize OpenAI Request</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>API Key:</label>
            <input
              type="text"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              required
              style={{ width: "300px", marginBottom: "10px" }}
            />
          </div>

          <div>
            <label>Article:</label>
            <textarea
              value={article}
              onChange={(e) => setArticle(e.target.value)}
              required
              rows="8"
              style={{ width: "100%", marginBottom: "10px" }}
              placeholder="Paste your article here..."
            />
          </div>

          <div>
            <label>Prompt:</label>
            <input
              type="text"
              value={tprompt}
              onChange={(e) => setTprompt(e.target.value)}
              required
              style={{ width: "100%", marginBottom: "10px" }}
              placeholder="Enter your prompt here..."
            />
          </div>

          <div>
            <label>Tokens:</label>
            <input
              type="number"
              value={tokens}
              onChange={(e) => setTokens(e.target.value)}
              required
              style={{ width: "100px", marginBottom: "10px" }}
            />
          </div>

          <button type="submit" style={{ padding: "10px 20px" }}>
            Send Request
          </button>
        </form>
      </section>

      {/* Section 2: Response Preview */}
      <section style={{ padding: "20px" }}>
        <h2>Response Preview</h2>
        <pre style={{ backgroundColor: "#f9f9f9", padding: "10px", borderRadius: "5px" }}>
          {response || "The response will appear here after you submit the form."}
        </pre>
      </section>
    </div>
  );
}

export default OpenAiRequest;
